<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create New Meter"
                    : "Update Meter"
                }}
              </strong>
            </div>
            <b-row>
              <BuildingDropdown :server-params="form"
                                :on-select="getUnits"
                                :form-group="true" />
              <b-col sm="12">
                <form-group :validator="$v.form.serialNo"
                            label="Meter Serial#"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter meter serial#"
                                autocomplete="off"
                                v-model="form.serialNo"></b-form-input>
                </form-group>
                <form-group :validator="$v.form.units"
                            label="Unit#"
                            :use-horizontal="false">
                  <treeselect v-model="form.units"
                              placeholder="Select Unit#"
                              :multiple="true"
                              :options="options.unit" />
                </form-group>
                <form-group :validator="$v.form.installedDate"
                            label="Installed Date"
                            :use-horizontal="false">
                  <b-form-input type="date"
                                placeholder="Enter installed date"
                                v-model="form.installedDate"></b-form-input>
                </form-group>
                <form-group label="Remarks" :use-horizontal="false">
                  <b-textarea placeholder="Enter remarks"
                              v-model="form.remarks"></b-textarea>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                {{ $route.name == route.form ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { dateFormat } from "@/shared/utils";
  import { required } from "vuelidate/lib/validators";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    data: () => ({
      route: {
        form: "MeterManagementCreate",
        table: "MeterManagement",
      },
      form: {
        buildingId: null,
        serialNo: null,
        units: [],
        installedDate: null,
        remarks: null,
      },
      options: {
        building: [],
        unit: [],
      },
    }),
    validations: {
      form: {
        serialNo: { required },
        units: { required },
        installedDate: { required },
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      const self = this;
      self.getBuildings();
      self.getUnits();
      if (self.$route.name != self.route.form) {
        self.get();
      }
    },
    methods: {
      getBuildings() {
        const self = this;

        self.$store
          .dispatch("apis/get", {
            url: `/common/building`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.building = response.data.data;
            }
          });
      },
      getUnits() {
        const self = this;

        self.$store
          .dispatch("apis/get", {
            url: `/common/unit`,
            params: self.form,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.unit = response.data.data.map((x) => ({
                id: x.unit,
                label: x.unit,
              }));
            }
          });
      },
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/meter/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                buildingId: response.data.building.id,
                serialNo: response.data.serialNo,
                units: response.data.units,
                installedDate: dateFormat(
                  response.data.installedDate,
                  "YYYY-MM-DD"
                ),
                remarks: response.data.remarks,
              };
            }
            loader.hide();
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this meter. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/meter";
        } else {
          _confirmText = "You are about to update this meter. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/meter/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
